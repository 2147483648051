@import url('./assets/lib/hljs/styles/atom-one-light.css');
@import url('../node_modules/ng-zorro-antd/src/ng-zorro-antd.min.css');
@import url('../node_modules/@fortawesome/fontawesome-free/css/all.min.css');
@import './assets/scss/app.scss';

// Create Text color of each tone from theme
@each $tone in $tone-loop {
    .text-#{nth($tone, 1)} {
        color: #{nth($tone, 1)} !important;
    }
}

.bg-lighter-gray {
    background-color: $gray-lighter;
}